import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes, useNavigate } from "react-router-dom";
import constants from "./constants";
import { getCompanies } from "./api";
import LoginPage from "./components/LoginPage/LoginPage";
import Loader from "./components/Loader/Loader";
import Alert from "./components/Alert/Alert";
import Page from "./components/Page/Page";
import DataContainer from "./container/DataContainer";
import Overview from "./components/pages/Overview/Overview";
import Admin from "./components/pages/Admin/Admin";
import Settings from "./components/pages/Settings/Settings";
import CompanyContainer from "./container/CompanyContainer";
import GoalContainer from "./container/GoalContainer";
import CreateSurvey from "./components/pages/CreateSurvey/CreateSurvey";
import CreateSurveyContainer from "./container/CreateSurveyContainer";
import './App.scss';

const Redirect = ({ to, user }) => {
  const navigate = useNavigate();
  useEffect(() => {
    switch (to) {
      case 'company':
        if (user.companyId) {
          navigate(`/company/${user.companyId}/dashboard?page=overview`);
        }
        break;
      default:
        navigate("/");
    }
  }, [navigate, to, user])
  return null;
}

function App() {
  const { isAuthenticated, user, isLoading, error, getIdTokenClaims } = useAuth0();
  const [companies, setCompanies] = useState({});
  const [hasError, setHasError] = useState(false);
  const [thisUser, setThisUser] = useState(null);

  useEffect(() => {
    getIdTokenClaims().then(res => {
      if (!!res) {
        setThisUser(res);
        getCompanies(res.__raw).then(res => {
          setCompanies(res);
        }).catch(e => {
          setHasError(true);
        });
      }
    });
  }, [isAuthenticated, getIdTokenClaims, user]);

  if (isLoading) {
    return <div className="App__loader"><Loader /></div>;
  }

  if (error || hasError) {
    return <div><Alert /></div>
  }

  return (
    <div
      className='App'
    >
      <Routes>
        {!isAuthenticated && (
          <>
            <Route path="/" element={<LoginPage />} />
            <Route
              path="/*"
              element={<Redirect />}
            />
          </>
        )}
        {isAuthenticated && (
          <>
            {(user[constants.namespace.role] === 'admin') ? (
              <>
                <Route
                  path="/"
                  element={(
                    <Page companies={companies} user={user} active="companies">
                      <Admin user={thisUser} companies={companies} />
                    </Page>
                  )}
                />
              </>
            ) : (
              <Route
                path="/*"
                element={<Redirect to="company" user={user} />}
              />
            )}
            (<>
              <Route
                path="/company/:company/dashboard"
                element={(
                  <Page companies={companies} user={user} hideHeader={true} active="dashboard">
                    <DataContainer user={thisUser} companies={companies}>
                      <Overview />
                    </DataContainer>
                  </Page>
                )}
              />
              <Route
                path="/company/:company/admin"
                element={(
                  <Page companies={companies} user={user} backgroundColor="white" active="admin">
                    <CompanyContainer user={thisUser} >
                      <Settings />
                    </CompanyContainer>
                  </Page>
                )}
              />
              <Route
                path="/company/:company/update/survey/:survey/:step"
                element={(
                  <Page user={user} backgroundColor="lightGray">
                    <CreateSurveyContainer user={thisUser}>
                      <CreateSurvey />
                    </CreateSurveyContainer>
                  </Page>
                )}
              />
              <Route
                path="/company/:company/create/survey/:step"
                element={(
                  <Page user={user} backgroundColor="lightGray" active="admin">
                    <CreateSurveyContainer user={thisUser}>
                      <CreateSurvey />
                    </CreateSurveyContainer>
                  </Page>
                )}
              />
            </>)
          </>
        )}
        <Route
          path="/*"
          element={<Redirect />}
        />
      </Routes>
    </div>
  );
}

export default App;
