import React, { Fragment } from "react";
import { createClusters } from "../../../helpers";
import Tooltip from "../../Tooltip/Tooltip";
import "./ClusterLine.scss";

const ClusterLine = ({ data, prefix }) => {
    let clusters = [{ avg: 0, data: [] }];
    let average = 0;
    if (data.length > 0) {
        clusters = createClusters(data);
        average = data.reduce((prev, curr) => prev + curr, 0) / data.length;
    }
    const maxLength = clusters.reduce((prev, curr) => (curr.data.length > prev && curr.data.length) || prev, 0);
    const step = 20;
    const maxValue = Math.ceil((clusters[clusters.length - 1].avg + 30) / step) * step;
    const offset = maxValue / 4;
    return (
        <div className="ClusterLine">
            <div className="ClusterLine__label">
                <span>0{prefix}</span>
            </div>
            {Array.from({ length: 4 }).map((x, index) => (
                <div key={index} className="ClusterLine__label" style={{ left: `${25 * (index + 1)}%` }}>
                    <span>{Math.round((index + 1) * offset)}{prefix}</span>
                </div>
            ))}
            {clusters.map((cluster, index) => {
                const size = 3 * Math.max(cluster.data.length / maxLength, 0.2);
                return (
                    <Fragment key={`${cluster.avg}-${index}`}>
                        <div
                            className="ClusterLine__dot"
                            style={{
                                left: `calc(${cluster.avg / maxValue * 100}% - ${size / 2}rem)`,
                                height: `${size}rem`,
                                width: `${size}rem`,
                            }}
                        >
                        </div>
                        <div
                            className="ClusterLine__tooltip"
                            style={{
                                width: `${size}rem`,
                                left: `calc(${cluster.avg / maxValue * 100}% - ${size / 2}rem)`
                            }}
                        >
                            <Tooltip text={prefix + Math.round(cluster.avg)} />
                        </div>
                    </Fragment>
                )
            })}
            <div
                className="ClusterLine__avg"
                style={{ left: `calc(${average / maxValue * 100}%)` }}
            >
                Average: <b>{prefix}{Math.round(average)}</b>
            </div>
        </div>
    );
};

export default ClusterLine;