import { tabsAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
    // define the part you"re going to style
    tab: {
        bg: "#f9f9f9",
        color: "black",
        _selected: {
            color: "black",
            opacity: 1,
            bg: "white"
        }
    },
    tabpanels: {
        bg: "white",
        border: "1px solid",
        borderColor: "gray.200",
        borderRadius: "0 0 8px 8px"
    },

})

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle })