const ProgressTheme = {
    baseStyle: {
        track: { bg: "gray.400" }
    },
    variants: {
        yellow: { filledTrack: { bg: "overview.yellow" } },
        blue: { filledTrack: { bg: "overview.blue" } },
        pink: { filledTrack: { bg: "overview.pink" } }
    }
}

export default ProgressTheme;