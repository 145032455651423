import React from "react";
import { Box, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import "./Description.scss";

const Description = ({ description }) => (
    <Popover>
        <PopoverTrigger>
            <div className="Description">?</div>
        </PopoverTrigger>
        <PopoverContent>
            <Box p="1rem" textAlign="left">
                {description}
            </Box>
        </PopoverContent>
    </Popover>
);

export default Description;