import React from "react";
import Button from "./Button";
import { ReactComponent as Pen } from '../../assets/icons/pen.svg';
import { ReactComponent as TrashCan } from '../../assets/icons/trash-can.svg';
import { ReactComponent as Share } from '../../assets/icons/share.svg';
import { ReactComponent as Plane } from '../../assets/icons/plane.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload.svg';
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg';
import { ReactComponent as Questionmark } from '../../assets/icons/questionmark.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { fontSizes, fontWeights } from "../../styles/fonts";
import colors from "../../styles/colors";
import './ButtonAction.scss';

const ButtonAction = ({ onClick, type, text, style: propsStyle, disabled, fullWidth, innerStyle, backgroundColor, color, borderColor, fill }) => {
    const action = {
        primary: {
            color: 'white',
            backgroundColor: 'green',
            borderColor: 'green',
            text,
            innerStyle
        },
        secondary: {
            color: 'green',
            backgroundColor: 'transparent',
            borderColor: 'green',
            text,
            innerStyle
        },
        custom: {
            color: color || 'black',
            backgroundColor: backgroundColor || 'white',
            borderColor: borderColor || 'black',
            text,
            innerStyle,
            style: propsStyle
        },
        edit: {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <Pen />,
            innerStyle
        },
        delete: {
            color: 'white',
            backgroundColor: 'red',
            borderColor: 'red',
            text: (text && 'Delete' + text) || 'Delete',
            innerStyle
        },
        "delete--small": {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <TrashCan fill={colors.color__black} />,
            innerStyle
        },
        share: {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <Share />,
            innerStyle
        },
        send: {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <Plane />,
            innerStyle
        },
        question: {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <Questionmark />,
            innerStyle
        },
        add: {
            color: 'white',
            backgroundColor: 'greenDark',
            borderColor: 'greenDark',
            text: 'Add',
            innerStyle
        },
        save: {
            color: 'white',
            backgroundColor: 'greenDark',
            borderColor: 'greenDark',
            text: text || 'Save',
            innerStyle
        },
        'save--small': {
            color: 'green',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: 'Save',
            innerStyle,
            style: {
                fontSize: fontSizes.fss,
                fontWeight: fontWeights.fwRegular
            }
        },
        'save-cloud': {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <Upload />,
            innerStyle
        },
        check: {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <Checkmark fill="black" />,
            innerStyle
        },
        cancel: {
            backgroundColor: 'transparent',
            color: 'green',
            borderColor: 'green',
            text: 'Cancel',
            innerStyle
        },
        'cancel--small': {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: 'Cancel',
            innerStyle,
            style: {
                fontSize: fontSizes.fss,
                fontWeight: fontWeights.fwRegular
            }
        },
        'undo--small': {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <>&#x293A;</>,
            innerStyle,
            style: {
                fontSize: '3rem'
            }
        },
        close: {
            backgroundColor: 'transparent',
            color: 'greenDark',
            borderColor: 'greenDark',
            text: 'Close',
            innerStyle
        },
        "close--small": {
            color: 'black',
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            text: <Cross fill={colors.color__black} />,
            innerStyle
        },
        new: {
            backgroundColor: 'greenDark',
            color: 'white',
            borderColor: 'greenDark',
            text: 'Add' + (text ? ' ' + text : ''),
            innerStyle
        },
        cross: {
            backgroundColor: 'transparent',
            color: 'black',
            borderColor: 'transparent',
            text: <div className="ButtonAction__cross"></div>,
            innerStyle
        }
    }
    if (!type || !action[type]) return null;

    return (
        <div className="ButtonAction" style={{ ...action[type].style, ...(!!fullWidth ? { width: '100%' } : {}) }}>
            <Button onClick={onClick} {...action[type]} disabled={disabled}><div className="ButtonAction__inner" style={{ ...action[type].style, ...innerStyle }}>{action[type].text}</div></Button>
        </div>
    )
}

export default ButtonAction;